<template>
  <div class="row mx-0" v-if="donation">
    <div class="col-12 py-3 px-0" :class="{'lightgrey_bg': isOdd}">
      <!--DESKTOP-->
      <div class="row m-0 align-items-center d-none d-lg-flex">
        <div v-for="item, index in headerItems" :key="index" class="col text-center font15">
          <span v-if="item.displayValue === 'recurringType'">
            {{donation[item.displayValue]}}
          </span>
          <span v-else-if="item.displayValue === 'frequency'" >
            {{capitalize(donation[item.displayValue])}}
          </span>
          <span v-else-if="item.displayValue === 'startDate'">
            {{date(donation[item.displayValue])}}
          </span>
          <span v-else-if="item.displayValue === 'amount'">
            {{currency(donation[item.displayValue])}}
          </span>
          <div class="row align-items-center justify-content-end" v-else-if="item.displayValue === 'info'">
            <div class="col-auto px-1">
              <Button color="green" btnText="VIEW" size="small" @click="view" icon="isLoading" >
                <div class="spinner-border spinner-border-sm" role="status" v-if="isLoading">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </Button>
            </div>
            <div class="col-auto px-1">
              <Button color="darkgrey" icon="arrow" size="xxsmall" @click="print(donation)">
                <IconFile size="size12" />
              </Button>
            </div>
            <div class="col-auto px-1">
              <Button color="darkgrey_red" icon="arrow" size="xxsmall" @click="deleteOrder">
                <ios-close-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
          <span v-else>{{donation[item.displayValue]}}</span>
        </div>
      </div>
      <!--END DESKTOP-->
      <!--MOBILE-->
      <div class="row mx-0 align-items-center d-lg-none position-relative font13">
        <div v-for="item, index in mobileHeaderItems" :key="index" class="col p-0 text-center">
          <span v-if="item.displayValue === 'recurringType'">
            {{donation[item.displayValue]}}
          </span>
          <span v-else-if="item.displayValue === 'frequency'" >
            {{capitalize(donation[item.displayValue])}}
          </span>
          <span v-else-if="item.displayValue === 'startDate'">
            {{date(donation[item.displayValue])}}
          </span>
          <span v-else-if="item.displayValue === 'amount'">
            {{currency(donation[item.displayValue])}}
          </span>
          <Button v-else-if="item.displayValue === 'info'" color="green" btnText="VIEW" size="small" @click="view" icon="isLoading" >
            <div class="spinner-border spinner-border-sm" role="status" v-if="isLoading">
              <span class="visually-hidden">Loading...</span>
            </div>
          </Button>
          <span v-else>{{donation[item.displayValue]}}</span>
        </div>
      </div>
      <!--END MOBILE-->
    </div>
    <transition name="fade">
      <ViewRecurring v-if="isView" :item="donation" @closeView="closeView" @edit="edit" @editBank="editBank" @deleteOrder="deleteOrder" />
    </transition>
    <transition name="fade">
      <EditRecurring v-if="isEdit" :item="donation" @closeEdit="closeEdit" />
    </transition>
    <transition name="fade">
      <EditBankDetails v-if="isEditBank" :item="donation" @closeEdit="closeEditBank" />
    </transition>
    <transition name="fade">
      <CancelRecurringDonation v-if="isDelete" :item="donation" @closeDelete="isDelete = false" @close="closeAll" />
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconFile: defineAsyncComponent(() => import('@/components/icons/IconFile.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue')),
    ViewRecurring: defineAsyncComponent(() => import('../view/ViewRecurring.vue')),
    EditRecurring: defineAsyncComponent(() => import('../edit/EditRecurring.vue')),
    EditBankDetails: defineAsyncComponent(() => import('../editbank/EditBankDetails.vue')),
    CancelRecurringDonation: defineAsyncComponent(() => import('../../components/CancelRecurringDonation.vue'))
  },
  name: 'RecurringDonationItem',
  props: ['headerItems', 'mobileHeaderItems', 'donation', 'index', 'status'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      isSelected: false,
      isLoading: false,
      isView: false,
      isEdit: false,
      isEditBank: false,
      isDelete: false
    }
  },
  computed: {
    isOdd () {
      return this.index % 2
    }
  },
  methods: {
    ...mapActions(['downloadRecurringDonationSummary']),
    date (val) {
      const date = DateTime.fromMillis(parseInt(val))
      return date.toLocaleString(DateTime.DATE_FULL)
    },
    capitalize (val) {
      const lowercase = val.toLowerCase()
      return lowercase[0].toUpperCase() + lowercase.slice(1)
    },
    currency (val) {
      const number = parseFloat(Math.round(val * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      const currency = this.donation.donations[0].currency.symbol
      return currency + ' ' + number
    },
    view () {
      this.isLoading = true
      this.isView = true
      this.isLoading = false
    },
    closeView () {
      this.isView = false
    },
    edit () {
      this.isEdit = true
    },
    closeEdit () {
      this.isEdit = false
    },
    editBank () {
      console.log('EDIT')
      this.isEditBank = true
    },
    closeEditBank () {
      this.isEditBank = false
    },
    deleteOrder () {
      this.closeView()
      this.isDelete = true
    },
    closeAll () {
      this.isDelete = false
      this.$router.push('/managerecurringdonations')
    },
    async print (val) {
      console.log('PRINT HERE', val)
      await this.downloadRecurringDonationSummary(val.recurringDonationID)
    }
  }
}
</script>
